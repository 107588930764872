<template>
  <div class="container">
    <PublicHeader :totalName="`${type}${$t('with').title}`" routerName="With" />
    <div class="form">
      <div v-if="type === 'USDT'">{{ $t('with').ldz }}：</div>
      <div class="addrType" v-if="type === 'USDT'">
        <span class="item" :class="{ active: active === index }" v-for="(item, index) in list" :key="index"
          @click="active = index">
          {{ item }}
        </span>
      </div>

      <div>{{ $t('with').tbdz }}：</div>
      <van-field v-model="addr" @click="
        $router.push({
          name: 'MoneyAddr',
          query: {
            select: 'ture',
            type
          }
        })
        " :placeholder="$t('with').tips" />
      <div>{{ $t('with').tbsl }}：</div>
      <van-field v-model="Amount" type="number" />
      <p style="font-size: 12px; color: #b3adad">
        {{ $t('with').ky }}：{{ getBalance() }} {{ type }}
      </p>
      <div>{{ $t('with').zjmm }}：</div>
      <van-field v-model="CashPassword" type="password" />

      <div class="tips">
        <p>
          {{ $t('with').sxf }}：<br />
          {{ userInfo.Charge }}%
        </p>
        <p class="num">
          {{ $t('with').dz }}： <br />
          <span>{{ getAmount() }} {{ type }}</span>
        </p>
      </div>
    </div>

    <van-button class="btn" type="info" round block color="#8e1473" @click="handleSub">{{
      $t('public').submit
    }}</van-button>
  </div>
</template>

<script>
import PublicHeader from '@/components_pc/publicHeaderNew'
import transactionApi from '@/api/transaction'
import userApi from '@/api/user'
export default {
  components: {
    PublicHeader
  },
  data() {
    return {
      addr: '',
      CashPassword: '',
      type: '',
      active: 0,
      Amount: null,
      userInfo: {
        CoinAmountInfo: {}
      },
      list: ['ERC20', 'TRC20', 'OMNI']
    }
  },
  mounted() {
    this.type = this.$route.query.type
    if (this.$route.query.info) {
      this.addrInfo = this.$route.query.info
      this.addr = this.addrInfo.CurrencyUrl
    }
    this.getUserInfo()
  },
  methods: {
    getAmount() {
      return ((100 - this.userInfo.Charge) * this.Amount) / 100
    },
    getBalance() {
      return this.userInfo.LegalAmountInfo[this.type]
    },
    async getUserInfo() {
      this.userInfo = await userApi.userInfo()
    },
    async handleSub() {
      if (!this.addr) {
        this.$toast(this.$t('with').tips)
        return false
      }
      if (!this.Amount) {
        this.$toast(this.$t('with').tips1)
        return false
      }

      if (this.CashPassword === '') {
        this.$toast(this.$t('with').tip2)
        return false
      }
      const form = {
        WalletAddressId: this.addrInfo.Id,
        Amount: Number(this.Amount),
        CashPassword: this.CashPassword
      }
      await transactionApi.lower(form)
      this.$toast(this.$t('moneyAddr').success)
      setTimeout(() => {
        this.$router.push({ name: 'Home' })
      }, 1000)
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  padding-top: 44px;
  margin: 0 25%;
  .form {
    margin: 20px 10px;
    border-radius: 4px;
    background: #17181e;
    padding: 15px;

    div {
      font-size: 14px;
      margin: 10px 0;
    }

    .addrType {
      margin-bottom: 10px;
      display: flex;

      .item {
        width: 100px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #dddde9;
        color: #333;
        border-radius: 4px;
        margin: 0 10px;
      }

      .active {
        background: #fff;
        color: #e143ac;
        border: 1px solid #e143ac;
      }
    }

    .van-field {
      border-radius: 5px;
    }

    .tips {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      line-height: 1.2;

      span {
        font-weight: bold;
        color: #e143ac;
        font-size: 16px;
      }
    }
  }

  .btn {
    margin: 30px auto 0;
    // width: 95vw;
  }
}
</style>
