var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('PublicHeader',{attrs:{"totalName":`${_vm.type}${_vm.$t('with').title}`,"routerName":"With"}}),_c('div',{staticClass:"form"},[(_vm.type === 'USDT')?_c('div',[_vm._v(_vm._s(_vm.$t('with').ldz)+"：")]):_vm._e(),(_vm.type === 'USDT')?_c('div',{staticClass:"addrType"},_vm._l((_vm.list),function(item,index){return _c('span',{key:index,staticClass:"item",class:{ active: _vm.active === index },on:{"click":function($event){_vm.active = index}}},[_vm._v(" "+_vm._s(item)+" ")])}),0):_vm._e(),_c('div',[_vm._v(_vm._s(_vm.$t('with').tbdz)+"：")]),_c('van-field',{attrs:{"placeholder":_vm.$t('with').tips},on:{"click":function($event){return _vm.$router.push({
        name: 'MoneyAddr',
        query: {
          select: 'ture',
          type: _vm.type
        }
      })}},model:{value:(_vm.addr),callback:function ($$v) {_vm.addr=$$v},expression:"addr"}}),_c('div',[_vm._v(_vm._s(_vm.$t('with').tbsl)+"：")]),_c('van-field',{attrs:{"type":"number"},model:{value:(_vm.Amount),callback:function ($$v) {_vm.Amount=$$v},expression:"Amount"}}),_c('p',{staticStyle:{"font-size":"12px","color":"#b3adad"}},[_vm._v(" "+_vm._s(_vm.$t('with').ky)+"："+_vm._s(_vm.getBalance())+" "+_vm._s(_vm.type)+" ")]),_c('div',[_vm._v(_vm._s(_vm.$t('with').zjmm)+"：")]),_c('van-field',{attrs:{"type":"password"},model:{value:(_vm.CashPassword),callback:function ($$v) {_vm.CashPassword=$$v},expression:"CashPassword"}}),_c('div',{staticClass:"tips"},[_c('p',[_vm._v(" "+_vm._s(_vm.$t('with').sxf)+"："),_c('br'),_vm._v(" "+_vm._s(_vm.userInfo.Charge)+"% ")]),_c('p',{staticClass:"num"},[_vm._v(" "+_vm._s(_vm.$t('with').dz)+"： "),_c('br'),_c('span',[_vm._v(_vm._s(_vm.getAmount())+" "+_vm._s(_vm.type))])])])],1),_c('van-button',{staticClass:"btn",attrs:{"type":"info","round":"","block":"","color":"#8e1473"},on:{"click":_vm.handleSub}},[_vm._v(_vm._s(_vm.$t('public').submit))])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }